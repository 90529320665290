.asset-manager {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.manager-box {
  width: 95%;
}

.title {
  margin-bottom: 15px !important;
}

.flex-space {
  display: flex;
  justify-content: space-between;
}

.sidebar-link {
  width: 100%;
  text-decoration: none;
  color: black;

  :hover {
    text-decoration: underline;
  }
}

.header-div {
  flex-grow: 1;
}

.header-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
}

.sign-in-card {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  padding: 32px;
  gap: 16px;
  margin: auto;
  max-width: 450px;
  box-shadow: hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px;
}

.sign-in-container {
  min-height: 100%;
  padding: 32px;
}

[class*="MuiTablePagination"] {
  margin: 0;
  padding: 0;
  max-height: 46px !important;
  min-height: 0px !important;
}

[class*="MuiTableCell"] {
  border: 0 !important;
}

tr:nth-child(4n), tr:nth-child(4n-1) {
  background-color: #F0F0F0;
}

.white-text-black-background {
  color: white !important;
  background-color: black !important;
}

.MuiTableSortLabel-icon { /*makes svg arrow white*/
  filter: brightness(0) saturate(100%) invert(100%);
}

.empty-table-cell {
  background-color: black !important;
  width: 0;
}

.table-loading {
  padding-left: 58px !important;
}